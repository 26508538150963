import React from 'react';
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';


export default function Navbar() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const NavBarComponent = () => 
    <div style={{zIndex: 2}} className='navBar'>
    <Grid container justifyContent="center" alignItems="center" textAlign='center'> 
      <Grid item xs={12} md={3} align='center' style={{marginTop: '6px', marginBottom: '6px'}}>
        <Link to='/' className='navBarLink'>BASIC Cognitive</Link>
      </Grid> 
      <Grid item xs={12} md={1} align='center' style={{marginTop: '6px', marginBottom: '6px'}}>
        <Link to='/' className='navBarLink'>Home</Link>
      </Grid>
      <Grid item xs={12} md={2} align='center' style={{marginTop: '6px', marginBottom: '6px'}}>
        <Link to='/news' className='navBarLink'>In the News</Link>
      </Grid>
      <Grid item xs={12} md={2} align='center' style={{marginTop: '6px', marginBottom: '6px'}}>
        <Link to='/resourceLinks' className='navBarLink' style={{textAlign:'right'}}>Resource Links</Link>
      </Grid>
      <Grid item xs={12} md={2} align='center'>
              <UncontrolledDropdown nav inNavbar style={{listStyleType:'none'}}>
                <DropdownToggle nav caret style={{color:'whitesmoke'}}>
                  About us
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem style={{height:'40px'}}>
                    <Link to='/fAQs' className='navBarSubLink'>
                      Frequently Asked Questions
                    </Link>
                  </DropdownItem>
                  <DropdownItem style={{height:'40px'}}>
                    <Link to='/fAQsForEnrolledParticipants' className='navBarSubLink'>
                      For Existing Participants
                    </Link>
                  </DropdownItem>
                  <DropdownItem style={{height:'40px'}}>
                    <Link to='/meetOurTeam' className='navBarSubLink'>
                      Meet Our Team
                    </Link>
                  </DropdownItem>
                  <DropdownItem style={{height:'40px'}}>
                    <Link to='/forPhysicians' className='navBarSubLink'>
                      For Physicians and Researchers
                    </Link>
                  </DropdownItem>
                  <DropdownItem style={{height:'40px'}}>
                    <Link to='/publications' className='navBarSubLink'>
                      Publications
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
        </Grid>
        <Grid item xs={12} md={1}align='center' style={{marginTop: '6px', marginBottom: '6px'}}>
          <Link to='/contact' className='navBarLink'>Contact</Link>
        </Grid>
    </Grid>
    </div>
  
    return (
        <nav>
          <div style={{backgroundImage: 'linear-gradient(#6d94bf,#446e9b 50%,#3e648d)', fontSize: '1.2em', color:'whitesmoke'}}>
            <NavBarComponent />
          </div>
        </nav>
    )
}