import React from 'react'
import '../styles/global.css';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import Navbar from "./Navbar";
import Grid from '@mui/material/Grid';

export default function Header(){
    return(
        <header>
            <Grid container spacing={2} className='applicationHeader' justifyContent="center" alignItems="center">
                <Grid item xs={12} style={{marginLeft:'10%'}}>
                    <Link to='/' >
                        <StaticImage
                            src={"../images/logos/basic_c_logo.gif"}
                            alt="Logo"
                            objectFit="fill"
                            placeholder="blurred"
                            width={255}
                            height={100}
                        />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Navbar/>
                    </div>
                </Grid>
            </Grid>
        </header>
    )
}