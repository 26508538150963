import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import Grid from '@mui/material/Grid'; 

export default function Footer() {
    return (
        <footer style={{backgroundColor: '#446e9b', color:'#eee', marginTop:'25px', paddingTop:'20px'}}> 
            <Grid container spacing={2} align='center'>
                <Grid xs={0} md={2} style={{alignContent:'center'}}>

                </Grid>
                <Grid xs={12} md={2} style={{alignContent:'center'}}>
                    <p> 
                        BASIC Cognitive Project<br/>
                        5151 Flynn Pkwy. Suite 506<br/>
                        Corpus Christi, TX 78411<br/>
                        361-257-1239
                    </p> 
                </Grid>
                <Grid xs={12} md={4}>
                    <div style={{paddingTop:'20px'}}> 
                        <div> 
                            <a href="mailto:umhs-basiccognitive@med.umich.edu" target='_blank' rel='noreferrer'> 
                                <EmailIcon color="success" sx={{ fontSize: 48 }}/>
                            </a> &nbsp;
                            <a href="https://www.facebook.com/basiccognitive" target='_blank' rel='noreferrer'> 
                                <FacebookIcon color="prmiary" sx={{ fontSize: 48 }}/> 
                            </a> &nbsp;
                            <a href="https://med.umich.edu" target='_blank' rel='noreferrer'> 
                                <StaticImage
                                    src={"../images/logos/umich_medicine.png"}
                                    alt="Logo"
                                    objectFit="fill"
                                    placeholder="blurred"
                                    height={48}
                                /> 
                            </a> &nbsp;
                            <a href="http://www.cctexas.com/departments/health-district" target='_blank' rel='noreferrer'> 
                                <StaticImage
                                    src={"../images/logos/public-health-logo.png"}
                                    alt="Logo"
                                    objectFit="fill"
                                    placeholder="blurred"
                                    height={48}
                                />  
                            </a> &nbsp;
                            <a href="https://www.tamucc.edu/science/" target='_blank' rel='noreferrer'> 
                                <StaticImage
                                    src={"../images/logos/science-engineering-horiz.png"}
                                    alt="Logo"
                                    objectFit="fill"
                                    placeholder="blurred"
                                    height={48}
                                />   
                            </a> 
                        </div> 
                    </div> 
                </Grid>
                <Grid xs={12} md={3}>
                    <div id="footer-about" style={{textAlign:'center', fontSize:'0.9em', wordWrap: 'break-word', marginLeft:"10px"}}> 
                        <p> 
                            BASIC Cognitive is a study sponsored by the National Institutes of Health. The study director is Dr. Lewis Morgenstern, neurologist and director of the University of Michigan's Stroke Program. 
                        </p> 
                    </div> 
                </Grid>
            </Grid>
        </footer>
    )
}