import React from 'react';
import '../styles/global.css';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import Helmet from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: '5%',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '10%',
      marginRight: '5%'
    },
    align:'center',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '10px',
      width: '90%',
      ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
        width: '85%',
      },
      height: '80%',
    },
  },
  customBorderRadius: {
    borderRadius: 25,
    zIndex: 2
  }
}));

export default function Layout({children}) {
    const classes = useStyles();
    const title = 'Basic Cognitive';
    return (
        <div style={{backgroundColor:'#eee'}}>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <Header />
            <div className={classes.root}>
              {children}
            </div>
            <div>
              <Footer />
            </div>
        </div>
    )
}